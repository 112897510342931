.Product {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}
.Product-container {
  width: 420px;
  margin: 0 auto;
  padding: 0 24px;
}

.products {
  margin: 24px 0;
  padding: 24px 0;
  padding-bottom: 360px !important;
  border-radius: 12px;
  width: 100%;
  background: rgb(240, 242, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-image {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product-image img {
  height: 80px !important;
  object-fit: cover;
  border-radius: 12px !important;
}

.product-desс {
  margin-top: 24px;
}

.prodcut-action {
  margin-top: 24px;
}

.notfound {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 22px;
}